import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static values = { url: String, text: String, isOtp: Boolean }

  copy (event) {
    event.preventDefault()

    const that = this;
    const url = that.urlValue;
    const text = that.textValue;
    const isOtp = that.isOtpValue;

    if (text) {
      ClipboardFunctions.copyText(text)
    } else {


      var xhttp = new XMLHttpRequest();
      xhttp.open("GET", url, false);
      xhttp.send();
      var response = JSON.parse(xhttp.responseText);

      if (isOtp) {
        ClipboardFunctions.copyText(response["otp"])
        flash("OTP copied. " + response["time_left"] + " seconds left", "success")
        return
      } else {
        ClipboardFunctions.copyText(response["password"])
      }
    }

    if (!isOtp) {
      flash("Copied to clipboard", "success")
    }

  }
}
