import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static targets = ["input"]
  copy (event) {
    event.preventDefault();
    ClipboardFunctions.copyText(this.inputTarget.value);
    flash("Copied to clipboard", "success")
  }
}
