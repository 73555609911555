<template>
  <div>
    <multiselect :options="options"
                  label="name" :multiple='true' :show-no-results="false" :hide-selected="true" v-model='users' track-by="code" placeholder="Type to search"></multiselect>
    <input type='hidden' name='users' :value='JSON.stringify(users)'>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  props: ["options"],
  components: {
    Multiselect
  },
  data: function () {
    return {
      users: [],
      isShown: false
    }
  }
}
</script>

