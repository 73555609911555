<template>
  <div class='restricter'>

    <div class='search search--hitcher' v-click-outside="onClickOutside">
      <i class="fas fa-search"></i>
      <input type='text'
        @click="showSearch = true"
        @input="onInput"
        @keydown.esc="showSearch = false"
        v-model='q' placeholder="Search to add...">
      <div class='search__box' v-show='showSearch'>
        <header class='search__header'>
          <label style="display: flex; align-items: center;"><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
        </header>
        <ul v-if='results.length > 0'>
          <li class='search__box__li__hitcher' v-for='(result,i) in results' :key="i">
            <h2>
              <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
              <a href='#' @click.prevent="add(result.id, result.object_type)" class='button button--success button--small'><i class='fas fa-plus'></i>Add</a>
              <a href='#' @click.prevent="add(result.id, result.object_type)">
                {{result.name}}
              <span class='search__span'>{{ result.object_type }}</span>
              </a>
            </h2>
          </li>
        </ul>
        <div class='empty' v-if='results.length == 0'>
          <p>No results found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import vClickOutside from 'v-click-outside'

export default {
  props: ["asset_layouts", "group_id"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    add (id, type) {
      var that = this;
      that.$api.post('/restrictions', {
        restriction: {
          group_id: that.group_id,
          restrictable_id: id,
          restrictable_type: type
        }
      })
      .then(function (response) {
        location.reload()
      }).catch(error => {
        alert(error.response.data)
      })
    },
    onInput () {

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      }

      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: "Company",
          include_archived: that.include_archived
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      showSearch: false,
      include_archived: false,
    }
  }
}
</script>

