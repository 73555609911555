// check-all_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainCheckbox"];

  toggle(event) {
      event.preventDefault();
      event.stopPropagation();

      const isChecked = this.mainCheckboxTarget.checked;
      const boxes = document.querySelectorAll(".exporter-input-option");

      boxes.forEach(box => {
        box.checked = isChecked;
      });
  }
}
