import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/Roler.vue";

export default class extends Controller {
  connect() {
    const el = this.element;
    Vue.use({
      install (Vue) {
        Vue.prototype.$api = axios.create({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        })
      }
    })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          exceeded_or_met_license_count: el.dataset.exceededOrMetLicenseCount === "true",
          uneditable: el.dataset.uneditable === "true",
          add_super_admin: el.dataset.addSuperAdmin === "true",
          presecurity_level: el.dataset.presecurityLevel,
          pregroup_id: el.dataset.pregroupId,
          precompany_id: el.dataset.precompanyId,
          precompany_name: el.dataset.precompanyName,
          groups: JSON.parse(el.dataset.groups)
        }
      })
    });
  }
}
