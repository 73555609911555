import { Controller } from "@hotwired/stimulus"
import flash from "../lib/flashes.js"
import axios from "axios"

export default class extends Controller {
  static targets = [ "circle" ]
  static values = {
    updateUrl: String,
    default: String
  }

  connect() {
    this.updateSelection()
  }

  select(event) {
    event.preventDefault()

    const color = event.currentTarget.getAttribute('data-color-picker-color')
    this.defaultValue = color
    this.updateSelection()

    const data = {"asset_password": {"color": color}};

    // Custom headers
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    };

    axios.put(this.updateUrlValue, data, { headers: headers })
    .then((response) => {
      flash('Color Updated!', 'success')
    }).catch(error => {
      console.error(error)
    })
  }

  updateSelection() {
    this.circleTargets.forEach((el) => {
      const color = el.getAttribute('data-color-picker-color')
      if (this.defaultValue === color) {
        el.firstElementChild.classList.add("selected")
      } else {
        el.firstElementChild.classList.remove("selected")
      }
    })
  }
}
