<template>
  <div>
    <div class="simple-crud">
      <div class='button-group'>
        <a href="#" class="button button--success" @click.prevent.stop="openAdd"><i class='fas fa-plus'></i>New Rack Role</a>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Color</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in items">
            <td>{{i.name}}</td>
            <td>{{i.description}}</td>
            <td>{{i.hex_color}}</td>
            <td class="simple-crud__actions">
              <a href='#' @click.prevent.stop="openEdit(i.slug, i.name, i.description, i.hex_color)">Edit</a>
              <a href='#' @click.prevent.stop="deleteItem(i.slug, index)"><i class='fas danger fa-trash'></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown'>
        <div class='manage_modal manage_modal--small'>
          <header>
            <h1 v-if="!formIsEdit">New Rack Role</h1>
            <h1 v-if="formIsEdit">Edit Rack Role</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
          </header>
          <div class="manage__modal__body">
            <form @submit.prevent.stop="createOrUpdateItem">
              <ul class='alert--errors' v-if="error">
                <li><i class="fas fa-exclamation-triangle"></i>{{error}}</li>
              </ul>
              <div class="form-group">
                <label for="role_name" class="required">Name</label>
                <input type="text" v-model='name' id="role_name" required>
              </div>
              <div class="form-group">
                <label for="role_description" class="required">Description</label>
                <input type="text" v-model='description' id="role_description" required>
              </div>
              <div class="form-group">
                <label for="hex_color" class="required">Color</label>
                <div class='circle__picker'>
                  <input type='hidden' :value='hex_color'>
                  <a href='#'  @click.prevent.stop='select("")'>
                    <span data-tippy-content="None" :class='{"circle circle--empty big": true, "selected": hex_color == ""}'><div class='password-circle-line'></div></span>
                  </a>
                  <a href='#'  @click.prevent.stop='select("red")'>
                    <span data-tippy-content="Red" :class='{"circle circle--red big": true, "selected": hex_color == "red"}'></span>
                  </a>
                  <a href='#'  @click.prevent.stop='select("blue")'>
                    <span data-tippy-content="Blue" :class='{"circle big circle--blue": true, "selected": hex_color == "blue"}'></span>
                  </a>
                  <a href='#'  @click.prevent.stop='select("green")'>
                    <span data-tippy-content="Green" :class='{"circle big circle--green": true, "selected": hex_color == "green"}'></span>
                  </a>
                  <a href='#'  @click.prevent.stop='select("purple")'>
                    <span data-tippy-content="Purple" :class='{"circle big circle--purple": true, "selected": hex_color == "purple"}'></span>
                  </a>
                  <a href='#'  @click.prevent.stop='select("pink")'>
                    <span data-tippy-content="Pink" :class='{"circle big circle--pink": true, "selected": hex_color == "pink"}'></span>
                  </a>
                  <a href='#'  @click.prevent.stop='select("yellow")'>
                    <span data-tippy-content="Yellow" :class='{"circle big circle--yellow": true, "selected": hex_color == "yellow"}'></span>
                  </a>
                </div>
              </div>
              <input v-if="!formIsEdit" type="submit" class="button button--primary" value="Create">
              <input v-if="formIsEdit" type="submit" class="button button--primary" value="Update">
            </form>
          </div>
        </div>
      </div>
    </Teleport>
  </div>

</template>

<script>
import Teleport from 'vue2-teleport';
import flash from "../lib/flashes.js";
export default {
  props: ["url", "current_ip", "toggle_url"],
  components: {
    Teleport
  },
  methods: {
    createOrUpdateItem () {
      var that = this;

      if (that.formIsEdit) {
        that.$api.put(`${that.url}/${that.slug}`, { rack_storage_role: {name: that.name, description: that.description, hex_color: that.hex_color}}).then(function (response) {
          fetch(that.url)
          .then((resp) => resp.json())
          .then(function(data) {
            that.items = data;
            that.clearEdit()
            flash('Role updated','success')
          })
        }).catch(error => {
          that.error = error.response.data.errors[0]
        })
      } else {
        that.$api.post(`${that.url}`, { rack_storage_role: {name: that.name, description: that.description, hex_color: that.hex_color}}).then(function (response) {
          fetch(that.url)
          .then((resp) => resp.json())
          .then(function(data) {
            that.items = data;
            that.clearEdit()
            flash('Role created','success')
          })
        }).catch(error => {
          that.error = error.response.data.errors[0]
        })
      }

    },
    clearEdit () {
      this.name = "";
      this.slug = "";
      this.description = "";
      this.isShown = false
      this.error = ""
    },
    openAdd () {
      this.clearEdit()
      this.formIsEdit = false;
      this.isShown = true
    },
    select (color) {
      this.hex_color = color;
    },
    openEdit (slug, name, description, hex_color) {
      this.clearEdit()
      this.slug = slug;
      this.formIsEdit = true;
      this.name = name;
      this.description = description;
      this.hex_color = hex_color;
      this.isShown = true
    },
    deleteItem (slug, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.url}/${slug}`).then(function (response) {
          that.items.splice(index, 1);
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
  mounted () {
    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.items = data;
    })
  },
  data() {
    return {
      enabled: true,
      name: "",
      hex_color: "",
      error: "",
      slug: "",
      formIsEdit: false,
      description: "",
      isShown: false,
      items: []
    }
  }
}
</script>
