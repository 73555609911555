<template>
  <div class='bulk_user_importer'>
    <input type='hidden' :value='payload' name='payload'>
    <div class='form-group'>
      <label for='file'>File for Import</label>
      <input id='file' type="file" @change="filesChange" accept=".csv" class="input-file">
    </div>
    <div class='form-group'>
      <div class='table-scroll'>
        <table class='table' v-if='recordSize > 0'>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Group ID</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='d in data'>
              <td v-if='d["0"]'>{{d["0"]}}</td><td class='danger' v-if='!d["0"]'>Missing</td>
              <td v-if='d["1"]'>{{d["1"]}}</td><td class='danger' v-if='!d["1"]'>Missing</td>
              <td v-if='d["2"]'>{{d["2"]}}</td><td class='danger' v-if='!d["2"]'>Missing</td>
              <td v-if='d["3"]'>{{d["3"]}}</td><td class='danger' v-if='!d["3"]'>Missing</td>
              <td v-if='d["3"] != "admin"'>{{d["4"] || default_group_name }}</td><td v-if='d["3"] == "admin"'></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button v-if='recordSize > 0' type='submit' class='button button--primary'>Import {{recordSize}} Users</button>
  </div>
</template>

<script>
  import Papa from "papaparse";
  import axios from 'axios';

  export default {
    props: ["asset_layouts", "url", "default_group_name"],
    methods: {
      filesChange (e) {
        var that = this;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        that.file = files[0]

        Papa.parse(this.file,
          {
            delimiter: "",  // auto-detect
            newline: "",  // auto-detect
            quoteChar: '"',
            escapeChar: '"',
            header: true,
            transformHeader: function(header, index) {
              return index
            },
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: false,
            comments: false,
            step: undefined,
            complete: function(results, file) {
              that.data = results.data;
              that.meta = results.meta;
              that.errors = results.errors;
              that.ready = true;
            },
            error: undefined,
            download: false,
            downloadRequestHeaders: undefined,
            skipEmptyLines: true,
            chunk: undefined,
            fastMode: undefined,
            beforeFirstChunk: undefined,
            withCredentials: undefined,
            transform: undefined,
            delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
          })
      }
    },
    data () {
      return {
        file: '',
        meta: [],
        ready: false,
        fileUploaded: false,
        data: [],
        errors: []
      }
    },
    computed: {
      recordSize () {
        return this.data.length
      },
      payload() {
        return JSON.stringify(this.data)
      }
    }
  }
</script>

<style scoped>

</style>