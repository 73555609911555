import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select';

export default class extends Controller {
  // Define static value to fetch the URL from HTML data attributes.
  static values = {
    url: String,
    recordType: { type: String, default: "Company"},
    selected: { type: Array},
    singleItem: { type: Boolean, default: false},
    placeholder: {type: String, default: "Select an option"},
    companyId: Number};
  static targets = ["select", "companyButton", "globalButton"];

  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    this.initializeTomSelect();
    this.searchGlobal = false;
  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.destroyTomSelect();
  }

  changeToGlobal (event) {
    event.preventDefault();
    //this.select.refreshItems()
    this.select.trigger("load");
    this.searchGlobal = true;
    this.companyButtonTarget.classList.remove("selected");
    this.globalButtonTarget.classList.add("selected");
  }

  changeToCompany (event) {
    event.preventDefault();
    // this.select.refreshItems()
    this.select.trigger("load");
    this.searchGlobal = false;
    this.companyButtonTarget.classList.add("selected");
    this.globalButtonTarget.classList.remove("selected");
  }

  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    // Return early if no element is associated with the controller.
    if (!this.element) return;

    // Fetch data for the dropdown.
    const fetchData = (search, callback) => {
      var url = `${this.urlValue}?q=${search}&type=${this.recordTypeValue}${this.companyIdValue && !this.searchGlobal ? ("&company=" + this.companyIdValue) : ""}&include_archived=false`;
      fetch(url)
        .then(response => response.json())  // Convert response to JSON.
        .then(data => callback(data))       // Send data to TomSelect.
        .catch(() => callback());           // Handle any errors.
    };

    const renderOption = (data, escape) => {
      if (data.company_name == null) {
        return `
          <div>
            <span>${escape(data.name)}</span>
          </div>
        `;
      } else {
        return `
          <div>
            <span>${escape(data.name)}</span>
            <span class='index__small'>${escape(data.asset_type)} / ${escape(data.company_name)}</span>
          </div>
        `;
      }
    };

    var plugins = this.singleItemValue ? [] : ["remove_button"];
    this.select = new TomSelect(this.selectTarget, {
      plugins: plugins,
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      maxItems: this.singleItemValue ? 1 : null,
      placeholder: this.placeholderValue,
      preload: false,
      create: false,
      sortField: { field: "name", direction: "asc" },
      load: fetchData,
      render: {
        option: renderOption
      },
    });
    this.selectedValue.forEach((value) => {
      this.select.addOption({
        id: value.id,
        name: value.name,
        company_name: value.company_name,
        asset_type: value.asset_type
      });
      this.select.addItem(value.id);
    });
  }

  // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}
