import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/PasswordTagger.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          can: el.dataset.can === 'true',
          index_url: el.dataset.indexUrl,
          update_url: el.dataset.updateUrl,
          folder: el.dataset.folder,
          autocomplete_suggestions: JSON.parse(el.dataset.autocompleteSuggestions)
        }
      })
    });
  }
}
