import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/BulkUserInviter.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          default_group_name: el.dataset.defaultGroupName,
        }
      })
    });
  }
}
