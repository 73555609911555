import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import store from "../store"
import App from "../components/PasswordEditor.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    this.vueInstance = new Vue({
      el,
      store,
      render: h => h(App, {
        props: {
          prelength: el.dataset.prelength,
          value: el.dataset.value,
          id: el.dataset.id,
          name: el.dataset.name,
          show_button: el.dataset.showButton === "true"
        }
      })
    });
  }
}
